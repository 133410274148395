import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// import { useRouter } from 'next/router';
import { getCookieConsentValue } from 'react-cookie-consent';
import { useSelector } from 'react-redux';
import QuoteCarousel from '../QuoteCarousel';
import ReactModal from 'react-modal';

import { getCookieConsent } from '../../redux/global/global.selector';

import style from './NewsDetail.module.scss';

import { useMediaQuery } from '@react-hook/media-query';
import { SmartLink } from '../SmartLink';
import Player from '../Player';
import { trackGaEvent } from '../../utils/gtm';

const ContactCard = ({ title, name, role, phone, email }) => {
    return (
        <div className={style.contact}>
            <div className={style['contact-title']}>{title}</div>
            {name.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{name.title}</div>
                    <div className={style['contact-item-value']}>{name.value}</div>
                </div>
            )}
            {role.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{role.title}</div>
                    <div className={style['contact-item-value']}>{role.value}</div>
                </div>
            )}
            {phone.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{phone.title}</div>
                    <div className={style['contact-item-value']}>
                        <a href={`tel:${phone.value}`} rel='noopener noreferrer'>
                            {phone.value}
                        </a>
                    </div>
                </div>
            )}
            {email.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{email.title}</div>
                    <div className={style['contact-item-value']}>
                        {email.value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? (
                            <a href={`mailto:${email.value}`} rel='noopener noreferrer'>
                                {email.value}
                            </a>
                        ) : (
                            <>{email.value}</>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

ContactCard.propTypes = {
    title: PropTypes.string,
    name: PropTypes.object,
    role: PropTypes.object,
    phone: PropTypes.object,
    email: PropTypes.object,
};

const DownloadCard = ({ image, mobileImage, title, description, cta }) => {
    const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    return (
        <div className={style.download}>
            <div className={`${style.treasersCard} ${image.url ? '' : style.noImage}`}>
                {image.url && (
                    <div className={style.treaserImageBox}>
                        <img src={isMobile ? mobileImage.url : image.url} alt={image.alt} />
                    </div>
                )}
                <div className={style.treaserCopy}>
                    <div className={style.treaserTitle}>
                        <p>{title}</p>
                    </div>
                    <div className={style.treaserDescription}>
                        <h3>{description}</h3>
                    </div>
                    <div className={style.treaserCta}>
                        <SmartLink href={cta.link.url}>{cta.text}</SmartLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DownloadCardContent = content => {
    return (
        <div className={style['article-text']}>
            {content.title && <h4>{content.title}</h4>}
            <div dangerouslySetInnerHTML={{ __html: content.downloadContent }} />
        </div>
    );
};

DownloadCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.object,
    image: PropTypes.object,
    mobileImage: PropTypes.object,
};

export const NewsDetailHeader = ({ header, sharethis, currentUrl, content }) => {
    return (
        <div className={style.header}>
            <div className={style.headerWrapper}>
                <div className={style['header-date']}>{header.date}</div>
                <div className={style['header-author']}>
                    {header.photo?.url && (
                        <div className={style['header-photo']}>
                            <img src={header.photo.url} alt={header.name} />
                        </div>
                    )}
                    {header.name && <div className={style['header-name']}>{`by ${header.name}`}</div>}
                </div>
                {/* <div
                    className={style.close}
                    onClick={() => router.back()}
                >
                    <span />
                    <span />
                </div> */}
            </div>
            <div
                className={style.headerWrapper}
                style={{
                    display: sharethis,
                }}
            >
                <div className={style['header-share-buttons']}>
                    <ul className={style.social}>
                        <li className={style['header-share-title']}>{header.share.title}</li>
                        {header.share &&
                            header.share?.items.map((item, idx) => (
                                <li
                                    key={idx}
                                    className={[
                                        style['social-item'],
                                        'st-custom-button',
                                        'track-ga-event',
                                        `icon-${item.icon}`,
                                    ].join(' ')}
                                    data-network={item.title}
                                    data-url={currentUrl}
                                    data-title={content.headline}
                                    data-image='/images/image.jpg'
                                    onClick={trackGaEvent('social_icon_click', item.title, currentUrl)}
                                />
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const NewsDetail = props => {
    const { header, settings, content } = props;
    const [currentUrl, getCurrentUrl] = useState('');
    // const router = useRouter();
    const [sharethis, setSharethis] = useState('none');
    const [downloadUserAccepted, setDownloadUserAccepted] = useState(false);

    const state = useSelector(state => state);

    const cookieAccept = getCookieConsent(state);

    const toggleUserAcceptDownload = useCallback(() => {
        setDownloadUserAccepted(!downloadUserAccepted);
    }, [downloadUserAccepted]);

    useEffect(() => {
        const cookieAccept = getCookieConsentValue();
        setSharethis(cookieAccept === 'false' || cookieAccept === undefined ? 'none' : 'block');
        getCurrentUrl(window.location.href);
        if (window.__sharethis__ && getCookieConsentValue() === 'true') {
            window.__sharethis__.initialize();
        }
    }, []);

    useEffect(() => {
        setSharethis(cookieAccept ? 'block' : 'none');
    }, [cookieAccept]);

    const TextFormat = content => {
        return (
            <div className={style['article-text']}>
                {content.title && <h4>{content.title}</h4>}
                <div dangerouslySetInnerHTML={{ __html: content.body }} />
            </div>
        );
    };

    const ImageFormat = content => {
        return (
            <div className={style['article-image']}>
                <img src={content.image.url} alt={content.image.alt} />
            </div>
        );
    };
    return (
        <>
            <div className={style.el}>
                <div className={[style.container, 'container'].join(' ')}>
                    <NewsDetailHeader header={header} sharethis={sharethis} content={content} currentUrl={currentUrl} />
                    <div className={style.content}>
                        <div className={style.contentWrapper}>
                            <h1 className={style.headline}>{content.headline}</h1>
                            <QuoteCarousel
                                quotes={content.quoteContents?.quotes}
                                currentUrl={currentUrl}
                                headline={content.headline}
                            />
                        </div>
                        <div className={style.contentWrapper}>
                            {content.video && (
                                <div className={style.video}>
                                    <Player poster={content.video.coverImage?.url} link={content.video?.video} />
                                </div>
                            )}
                            <div className={style.article}>
                                {content?.article?.map((item, i) => {
                                    return item.type === 'newsDetailText' || item.type === 'caseStudyText' ? (
                                        <TextFormat key={i} {...item} />
                                    ) : item.type === 'newsDetailImage' || item.type === 'caseStudyImage' ? (
                                        <ImageFormat key={i} {...item} />
                                    ) : null;
                                })}
                            </div>
                            {settings.requireDownloadAcceptTerms && !downloadUserAccepted && (
                                <div className={style.button}>
                                    <button
                                        type='button'
                                        className={style.acceptButton}
                                        onClick={toggleUserAcceptDownload}
                                    >
                                        <span className={style.title}>{settings.downloadAcceptButtonText}</span>
                                    </button>
                                </div>
                            )}
                            {!settings.requireDownloadAcceptTerms &&
                                content.downloadContents?.map((item, i) => <DownloadCardContent key={i} {...item} />)}
                            {!settings.requireDownloadAcceptTerms &&
                                content.download?.map((item, i) => <DownloadCard {...item} key={`download-${i}`} />)}
                            {content.contact?.slice(0, 3).map((item, i) => (
                                <ContactCard {...item} key={`contact-${i}`} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ReactModal
                isOpen={!!downloadUserAccepted}
                onRequestClose={toggleUserAcceptDownload}
                className='page-modal'
                overlayClassName='page-modal-overlay'
                contentLabel='Modal'
                closeTimeoutMS={800}
            >
                <button type='button' className='close-btn' onClick={toggleUserAcceptDownload}>
                    <span />
                    <span />
                </button>
                <div className={[style.el, style.modalEl].join(' ')}>
                    <div className={[style.modalContent, style.content].join(' ')}>
                        <div className={[style.modalContentWrapper, style.contentWrapper].join(' ')}>
                            {((settings.requireDownloadAcceptTerms && downloadUserAccepted) ||
                                !settings.requireDownloadAcceptTerms) &&
                                content.downloadContents?.map((item, i) => <DownloadCardContent key={i} {...item} />)}
                            {((settings.requireDownloadAcceptTerms && downloadUserAccepted) ||
                                !settings.requireDownloadAcceptTerms) &&
                                content.download?.map((item, i) => <DownloadCard {...item} key={`download-${i}`} />)}
                        </div>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

NewsDetail.propTypes = {
    header: PropTypes.object,
    settings: PropTypes.object,
    content: PropTypes.object,
};

NewsDetailHeader.propTypes = {
    header: PropTypes.string,
    sharethis: PropTypes.string,
    currentUrl: PropTypes.string,
    content: PropTypes.string,
};

export default NewsDetail;

NewsDetail.defaultProps = {};
